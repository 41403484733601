<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5">
          <div class="col-lg-12">
            <div class="text-center">
              <a href="/choose-outlet" class="float-start">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="font-22 mb-4">Onboarding Journey</h1>
            </div>
            <div class="row">
              <div class="col-lg-4 mb-4" v-for="(item, i) in list" :key="i">
                <div
                  class="card p-3 border journey-card completed"
                  v-on:click="moveToNextPage(item.title)"
                >
                  <div class="d-flex gap-3 align-items-center">
                    <div v-show="item.is_complete == 'Y'">
                      <i class="bi bi-check-circle-fill font-22 check-icon"></i>
                    </div>
                    <div v-show="item.is_complete == 'N'">
                      <i
                        class="bi bi-check-circle-fill font-22"
                        style="color: #bebebe"
                      ></i>
                    </div>
                    <div class="w-100">
                      <div
                        class="font-medium d-flex gap-3 justify-content-between font-16 mb-1"
                      >
                        {{ getItemDetails(item.title) }}
                      </div>
                      <p
                        :class="[
                          'font-14',
                          'text-secondary',
                          'mb-0',
                          item.is_complete === 'N' ? 'text-danger' : '',
                        ]"
                        v-show="item.is_complete === 'Y' || item.is_complete === 'N'"
                      >
                        {{
                          item.is_complete === "Y"
                            ? "All the details are filled"
                            : "*Please fill all the details"
                        }}
                      </p>
                    </div>
                    <span
                      ><i class="fa fa-angle-right font-20" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
              </div>
              <ErrorComponent @retry="retry()" ref="errorComponent" />
            </div>
          </div>
        </div>
        <div class="text-center">
          <button
            id="continue"
            type="button"
            class="btn btn-primary px-5"
            v-on:click="moveToHomePage"
            :disabled="buttonFlag"
          >
            Continue
          </button>
        </div>

        <button
          type="button"
          hidden
          id="add-partner-details"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#AddPartnerModal"
        ></button>

        <div
          class="modal fade"
          id="AddPartnerModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">AGREEMENT</h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                ></button>
              </div>
              <div class="modal-body">
                <iframe :src="content" width="100%" height="600px"></iframe>
              </div>
              <div
                class="signature-container text-center m-5"
                v-if="user_type == 'OWNER' && !owner_signature"
              >
                <canvas
                  id="signature-pad"
                  width="400"
                  height="200"
                  style="border: 1px solid #000"
                ></canvas>
                <div>
                  <button
                    class="btn-secondary btn-sm"
                    style="
                      margin-right: 15px !important;
                      font-size: 15px;
                      font-weight: 600;
                      height: 48px;
                      border: 0;
                      padding: 7px 27px;
                      line-height: 33px;
                      border-radius: 12px;
                      margin-right: 15px;
                    "
                    v-on:click="clearSignature()"
                  >
                    Clear
                  </button>
                  <button
                    class="btn btn-primary ml-2"
                    id="save-signature"
                    v-on:click="saveSignature()"
                  >
                    Save Signature
                  </button>
                </div>
              </div>
              <template v-if="is_acknowledged == 'N' && owner_signature">
                <div class="modal-footer" v-if="content && user_type == 'OWNER'">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="acknowledged"
                    true-value="Y"
                    false-value="N"
                    id="acknowledged"
                  />
                  <label class="form-check-label" for="acknowledged">
                    I Acknowledged
                  </label>
                  <button
                    type="button"
                    class="btn btn-primary"
                    id="save-data"
                    :disabled="acknowledged == 'N' || user_type != 'OWNER'"
                    v-on:click="send()"
                  >
                    I Acknowledged
                  </button>

                  <button class="btn btn-primary ml-2" v-on:click="openSignature()">
                    Edit Signature
                  </button>
                </div>
                <div class="modal-footer" v-else>
                  <h3 style="color: #004852">
                    Kindly ask the owner or partner to log in and accept to continue.
                  </h3>

                  <button
                    type="button"
                    class="logout btn-primary"
                    id="save-data"
                    v-on:click="handleLogout()"
                  >
                    Logout
                  </button>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import "./onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent";
import SignaturePad from "signature_pad";
export default {
  name: "OnboardingSteps",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      id: localStorage.getItem("workshop_id"),
      outlet_workshop_id: localStorage.getItem("outlet_workshop_id"),
      page_type: localStorage.getItem("page_type"),
      list: [],
      videoUploadFields: [0],
      buttonFlag: true,
      brands: [],
      user_type: "",
      is_acknowledged: "N",
      acknowledged: "N",
      content: "",
      signaturePad: null,
      signatureImage: "",
      owner_signature: null,
      temp_signature: null,
    };
  },

  mounted() {
    // this.getMeCall();
    if (this.page_type == "ADD_OUTLET" && this.outlet_workshop_id) {
      this.getWorkshopProgressList(this.outlet_workshop_id);
    } else {
      if (this.id) {
        this.getWorkshopProgressList(this.id);
      } else {
        this.$router.push("/choose-outlet");
      }
    }
  },
  methods: {
    retry() {
      if (this.page_type == "ADD_OUTLET" && this.outlet_workshop_id) {
        this.getWorkshopProgressList(this.outlet_workshop_id);
      } else {
        if (this.id) {
          this.getWorkshopProgressList(this.id);
        } else {
          this.$router.push("/choose-outlet");
        }
      }
    },
    getWorkshopProgressList(id) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-workshop-progress/" + id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          this.brands = res.brand_type;
          this.$sharedService.setMapValue("Brands", this.brands);
          if (res && res.list && res.list.length > 0) {
            this.list = res.list;
            for (var i = 0; i < this.list.length; i++) {
              if (this.list[i].is_complete == "N" && this.list[i].title != "PHOTOS") {
                this.buttonFlag = true;
                break;
              } else {
                this.buttonFlag = false;
              }
            }
            for (var j = 0; j < this.list.length; j++) {
              if (this.list[j].title == "AGREEMENT") {
                this.is_acknowledged = this.list[j].is_complete;
              }
            }
          }
          if (
            this.$store.getters.getPageType &&
            this.$store.getters.getPageType != "ONBOARDING"
          ) {
            localStorage.setItem("page_type", this.$store.getters.getPageType);
            this.$store.commit("setPageType", this.$store.getters.getPageType);
          } else {
            localStorage.setItem("page_type", "ONBOARDING");
            this.$store.commit("setPageType", "ONBOARDING");
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length == 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            this.$refs.errorComponent.setTitle("");
          }
        });
    },
    getItemDetails(item) {
      switch (item) {
        case "PERSONAL":
          return "Personal Details";
        case "DOCUMENT":
          return "Workshop Document";
        case "PHOTOS":
          return "Workshop Photos & Videos";
        case "VEHICLE":
          return "Select Vehicle";
        case "SERVICE":
          return "Select Services";
        case "COST":
          return "Select Periodic Service Cost";
        case "BRAND":
          return "Select Brands";
        case "TIMING":
          return "Workshop Timing";
        case "BANK":
          return "Bank Account";
        case "INSURANCE":
          return "Insurance";
        case "WORKING":
          return "Working Time";
        case "AGREEMENT":
          return "Agreement";
        default:
          return "";
      }
    },
    moveToNextPage(title) {
      if (title == "AGREEMENT") {
        this.getMeCall();
      } else if (title == "BRAND") {
        if (this.brands.length > 0) {
          if (this.brands[0] == "TYRE") {
            this.$router.push("/choose-tyre-brands").then(() => {
              this.$route.params.brands = this.brands;
            });
          } else if (this.brands[0] == "BATTERY") {
            this.$router.push("/choose-battery-brands");
          } else if (this.brands[0] == "CNG") {
            this.$router.push("/choose-cng-brands");
          }
        }
      } else {
        var url = this.getUrlByType(title);
        this.$router.push(url);
      }
    },
    getUrlByType(type) {
      switch (type) {
        case "PERSONAL":
          return "/personal-details";
        case "DOCUMENT":
          return "/workshop-details";
        case "PHOTOS":
          return "/photos-video";
        case "VEHICLE":
          return "/select-vehicle";
        case "BANK":
          return "/bank-details";
        case "TIMING":
          return "/working-time";
        case "INSURANCE":
          return "/insurance";
        case "SERVICE":
          return "/services";
        case "COST":
          return "/service-details";
        default:
          return "";
      }
    },
    moveToHomePage() {
      this.$router.push("/home");
    },
    getMeCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.user_type = res.result.type;
          this.owner_signature = res.result.signature;
          if (res.result.is_acknowledged == "N") {
            this.getRegisterCall();
          } else {
            this.agreement();
          }
        })
        .catch(() => {});
    },
    agreement() {
      this.$api
        .getAPI({
          _action: `get-agreement-detail`,
        })
        .then((res) => {
          this.content = res.url;
          document.getElementById("add-partner-details").click();
        })
        .catch(() => {});
    },
    getRegisterCall() {
      this.$api
        .getAPI({
          _action: `get-agreement`,
        })
        .then((res) => {
          this.content = res.url;
          document.getElementById("add-partner-details").click();
          if (!this.owner_signature) {
            const canvas = document.getElementById("signature-pad");
            this.signaturePad = new SignaturePad(canvas);
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Please contact to administration.",
            type: "error",
            duration: 10000,
            position: "top-right",
          });
          // localStorage.clear();
          // window.location.href = "/home";
        });
    },
    send() {
      this.$api
        .postAPI({
          _action: `acknowledged`,
          _buttonId: "save-data",
        })
        .then(() => {
          document.getElementById("close-modal").click();
          this.getMeCall();
          if (this.page_type == "ADD_OUTLET" && this.outlet_workshop_id) {
            this.getWorkshopProgressList(this.outlet_workshop_id);
          } else {
            if (this.id) {
              this.getWorkshopProgressList(this.id);
            } else {
              this.$router.push("/choose-outlet");
            }
          }
        })
        .catch(() => {});
    },
    handleLogout() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to logout ",
        icon: "Warning",
        showCancelButton: true,
        confirmButtonText: "Yes,",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(true);
          }
          this.$api
            .getAPI({
              _action: "logout",
            })
            .then(() => {
              if (this.$refs.errorComponent) {
                this.$refs.errorComponent.showLoader(false);
              }
              document.getElementById("close-modal").click();
              localStorage.clear();
              this.$api.clearCache();
              this.$store.commit("setPageType", "");
              this.$store.commit("setAccessToken", "");
              this.$store.commit("setWorkshopId", "");
              this.$router.push("/login");
            })
            .catch(() => {
              if (this.$refs.errorComponent) {
                this.$refs.errorComponent.showLoader(false);
              }
            });
        }
      });
    },
    openSignature() {
      this.temp_signature = this.owner_signature;
      this.owner_signature = null;
      setTimeout(() => {
        const canvas = document.getElementById("signature-pad");
        this.signaturePad = new SignaturePad(canvas);
      }, 500);
    },
    clearSignature() {
      this.signaturePad.clear();
      this.signatureImage = "";
      if (this.temp_signature) {
        this.owner_signature = this.temp_signature;
        this.temp_signature = null;
      }
    },

    saveSignature() {
      if (!this.signaturePad.isEmpty()) {
        const base64Image = this.signaturePad.toDataURL("image/png");
        const binaryImage = this.base64ToBlob(base64Image);
        this.sendSignatureToAPI(binaryImage);
      } else {
        alert("Please sign before submitting.");
      }
    },

    base64ToBlob(base64Data) {
      // Remove the header (e.g., "data:image/png;base64,")
      const base64WithoutHeader = base64Data.split(",")[1];
      const byteString = atob(base64WithoutHeader);
      const buffer = new ArrayBuffer(byteString.length);
      const view = new Uint8Array(buffer);

      for (let i = 0; i < byteString.length; i++) {
        view[i] = byteString.charCodeAt(i);
      }

      return new Blob([buffer], { type: "image/png" });
    },

    // Make an API call to save the signature
    sendSignatureToAPI(signatureImage) {
      this.$api
        .uploadImageAPI({
          _action: "/upload-document",
          _file: signatureImage,
          _key: "image",
          _body: {
            type: "SIGNATURE",
          },
          _buttonId: "save-signature",
        })
        .then((res) => {
          if (res) {
            this.content = res.url;
            this.temp_signature = null;
            this.owner_signature = res.path;
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
  },
};
</script>

