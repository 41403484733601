<template>
  <div class="login-page">
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5 mt-5">
          <div class="col-lg-12">
            <h1 class="font-22 mb-3 text-center">Choose Outlet</h1>
            <div class="row">
              <div class="col-lg-4" v-for="(item, i) in workshopList" :key="i">
                <div
                  class="card p-3 border outlet-card mt-3 selecte"
                  v-on:click="Check(item)"
                >
                  <div class="d-flex gap-3">
                    <div>
                      <img
                        :src="item.image ? item.image : `static/images/default.png`"
                        width="150"
                        height="100"
                        class="rounded-3 object-fit-cover"
                        alt=""
                      />
                    </div>
                    <div class="w-100">
                      <div
                        class="font-medium d-flex gap-3 justify-content-between font-16 mb-3"
                      >
                        {{ item.workshop_name }}
                        <span
                          ><i class="fa fa-angle-right font-20" aria-hidden="true"></i
                        ></span>
                      </div>
                      <p
                        class="font-14 text-secondary mb-0"
                        v-if="item.address_line_1.length > 70"
                      >
                        {{
                          manageAddress(item.address_line_1 + "," + item.address_line_2)
                        }}...
                      </p>
                      <p v-else class="font-14 text-secondary mb-0">
                        {{ item.address_line_1 }}, {{ item.address_line_2 }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorComponent ref="errorComponent" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import "./login.scss";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "ChooseOutlet",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      workshopList: [],
    };
  },

  mounted() {
    if (
      this.$store.getters.getPageType &&
      this.$store.getters.getPageType != "ONBOARDING"
    ) {
      this.$store.commit("setPageType", this.$store.getters.getPageType);
    } else {
      this.$store.commit("setPageType", "ONBOARDING");
    }
    this.getWorkshopList();
  },
  methods: {
    getWorkshopList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/workshop-list",
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (
            this.$store.getters.getPageType &&
            this.$store.getters.getPageType != "ONBOARDING"
          ) {
            localStorage.setItem("page_type", this.$store.getters.getPageType);
            this.$store.commit("setPageType", this.$store.getters.getPageType);
          } else {
            localStorage.setItem("page_type", "ONBOARDING");
            this.$store.commit("setPageType", "ONBOARDING");
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length <= 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }
          if (res.list && res.list.length > 0) {
            this.workshopList = res.list;
            if (this.workshopList.length == 1) {
              this.setWorkshop(this.workshopList[0].id);
            }
          } else {
            this.$router.push("/personal-details");
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            this.$refs.errorComponent.setTitle("");
          }
        });
    },

    setWorkshop(id) {
      if (id) {
        this.$api
          .postAPI({
            _action: "/set-workshop",
            _body: { id: id },
            _hide_loader: true,
          })
          .then((res) => {
            localStorage.setItem("access_token", res.access_token);
            this.$store.commit("setAccessToken", res.access_token);
            this.getMeCall(id);
          })
          .catch(() => {});
      }
    },

    getMeCall(id) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          localStorage.setItem("workshop_count", res.result.workshop_count);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          localStorage.setItem("workshop_id", id);
          this.$store.commit("setWorkshopId", id);

          if (
            this.$store.getters.getPageType &&
            this.$store.getters.getPageType != "ONBOARDING"
          ) {
            localStorage.setItem("page_type", this.$store.getters.getPageType);
            this.$store.commit("setPageType", this.$store.getters.getPageType);
          } else {
            localStorage.setItem("page_type", "ONBOARDING");
            this.$store.commit("setPageType", "ONBOARDING");
          }

          if(localStorage.getItem("page_type") == 'ADD_OUTLET'){
            localStorage.setItem("outlet_workshop_id", id);

          }
          localStorage.setItem("workshop_name", res.result.workshop.workshop_name);
          this.getWorkshopProgressList(id);
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },

    getWorkshopProgressList(id) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-workshop-progress/" + id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }

          var buttonFlag = "";
          if (res && res.list && res.list.length > 0) {
            for (var i = 0; i < res.list.length; i++) {
              if (res.list[i].is_complete == "N" && res.list[i].title != "PHOTOS") {
                buttonFlag = true;
                break;
              } else {
                buttonFlag = false;
              }
            }
            if (buttonFlag) {
              this.$router.push("/onboarding-steps");
            } else {
              this.$store.commit("setPageType", "HOME");
              this.$router.push("/home");
            }
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
    manageAddress(address) {
      return address.substring(0, 70);
    },
    Check(item) {
      if (item.status == "CLOSE_ACCOUNT") {
        var message =
          "Your account has been closed. Admistration will update the status.";
        this.$swal({
          title: "Are you sure?",
          text: message,
          icon: "warning",
          // showCancelButton: true,
          // confirmButtonText: "Yes, delete it!",
          // cancelButtonText: "No, cancel!",
        });
        return false;
      } else {
        if (item.role != "OWNER" && item.status == "PENDING") {
          var message1 =
            "You dont have access to these workshop until admin is not approved.";
          this.$swal({
            title: "Are you sure?",
            text: message1,
            icon: "warning",
          });
          return false;
        } else {
          this.setWorkshop(item.id);
        }
      }
    },
  },
};
</script>

