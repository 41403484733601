<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <!-- <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div> -->
        <div class="row justify-content-center pt-5 form-style">
          <div class="col-lg-10">
            <div class="text-center">
              <a @click="handleBack" class="float-start" style="cursor: pointer">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>

              <!-- --------------------------------------------------------Personal Details------------------------------------------------------------------------- -->

              <h1 class="font-22 mb-4">Personal Details</h1>
            </div>
            <Form @submit="saveDeatils()" class="form-group">
              <div class="row">
                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="" class="form-label"
                      >Authorized Representative Full Name</label
                    >
                    <Field
                      v-slot="{ field }"
                      name="name"
                      rules="required:Full name"
                      v-model="workshopDetails.name"
                    >
                      <input
                        type="text"
                        v-bind="field"
                        class="form-control"
                        v-model="workshopDetails.name"
                        placeholder="Enter full name"
                      />
                    </Field>
                  </div>
                  <ErrorMessage name="name" class="validation-msg" />
                </div>

                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Outlet Workshop Name</label>
                    <Field
                      v-slot="{ field }"
                      name="workshop-name"
                      rules="required:workshop name"
                      v-model="workshopName"
                    >
                      <input
                        type="text"
                        v-bind="field"
                        class="form-control"
                        v-model="workshopName"
                        placeholder="Enter workshop name"
                      />
                    </Field>
                  </div>
                  <ErrorMessage name="workshop-name" class="validation-msg" />
                </div>
                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="" class="form-label"
                      >Authorized Representative PAN Number
                    </label>
                    <Field
                      v-slot="{ field }"
                      name="PAN-Number"
                      rules="panNumber"
                      v-model="workshopDetails.pan_number"
                    >
                      <input
                        type="text"
                        v-bind="field"
                        class="form-control"
                        v-model="workshopDetails.pan_number"
                        placeholder="Enter PAN number"
                      />
                    </Field>
                  </div>
                  <ErrorMessage name="PAN-Number" class="validation-msg" />
                </div>
                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Email address</label>
                    <Field
                      v-slot="{ field }"
                      name="email-address"
                      rules="required:email address|email"
                      v-model="email"
                    >
                      <input
                        type="text"
                        v-bind="field"
                        class="form-control"
                        v-model="email"
                        placeholder="Enter email address"
                      />
                    </Field>
                  </div>
                  <ErrorMessage name="email-address" class="validation-msg" />
                </div>
                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Mobile Number</label>
                    <Field
                      v-slot="{ field }"
                      name="mobile-number"
                      rules="required:mobile number|mobileNumber"
                      v-model="workshopDetails.mobile_number"
                    >
                      <input
                        type="text"
                        v-bind="field"
                        class="form-control"
                        v-model="workshopDetails.mobile_number"
                        placeholder="Mobile Number (Prefetched)"
                        disabled="true"
                      />
                    </Field>
                  </div>
                  <ErrorMessage name="mobile-number" class="validation-msg" />
                </div>
                <div class="col-lg-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Primary number (optional)</label>
                    <Field
                      v-slot="{ field }"
                      name="primary-number"
                      rules="mobileNumber"
                      v-model="primaryNumber"
                    >
                      <input
                        type="text"
                        v-bind="field"
                        class="form-control"
                        v-model="primaryNumber"
                        placeholder="Mobile Number"
                      />
                    </Field>
                    <p class="mb-0 font-12 text-secondary">
                      Add on number will have same access as owner
                    </p>
                  </div>
                  <ErrorMessage name="primary-number" class="validation-msg" />
                </div>
                <div class="row border-top pt-3 mb-4">
                  <div class="col-lg-6 mb-3">
                    <label for="" class="font-medium mb-2">
                      Upload front side of PAN card</label
                    >
                    <div v-if="workshopDetails.pan_image_front" class="uploaded-photo">
                      <img
                        id="preview-pan-0"
                        :src="workshopDetails.pan_image_front ? workshopDetails.pan_image_front : 'static/images/default.png'"
                        class="image"
                        alt=""
                      />
                      <a
                        @click="deletePanCard()"
                        class="close-btn"
                        style="cursor: pointer"
                        ><img
                          src="static/images/close-icon.svg"
                          class="close-icon"
                          width="20"
                          alt=""
                      /></a>
                    </div>
                    <div class="upload-btn-container" v-else>
                      <input
                        type="file"
                        @change="panHandleImageUpload($event)"
                        accept="image/jpeg, image/png, image/jpg"
                      />
                      <label htmlFor="tb-file-upload h-auto" for="preview-pan-0">
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div>
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <span class="font-12 text-secondary"
                        >Supported files: Jpeg, PNG (Max. file size 4 MB)</span
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label for="" class="font-medium mb-2">
                      Upload Authorization Certificate/Letter (Optional)</label
                    >
                    <div v-if="workshopDetails.authorization_letter" class="uploaded-photo">
                      <img
                        id="preview-authorization-0"
                        :src="
                        workshopDetails.authorization_letter
                            ? workshopDetails.authorization_letter
                            : 'static/images/default.png'
                        "
                        class="image"
                        alt=""
                      />
                      <a
                        v-if="workshopDetails.authorization_letter"
                        @click="deleteAuthorizationImage()"
                        class="close-btn"
                        style="cursor: pointer"
                        ><img
                          src="static/images/close-icon.svg"
                          class="close-icon"
                          width="20"
                          alt=""
                      /></a>
                    </div>
                    <div class="upload-btn-container" v-else>
                      <input
                        type="file"
                        @change="authorizationHandleImageUpload($event)"
                        accept="image/jpeg, image/png, image/jpg"
                      />
                      <label
                        htmlFor="tb-file-upload h-auto"
                        for="preview-authorization-0"
                      >
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div>
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <span class="font-12 text-secondary"
                        >Supported files: Jpeg, PNG (Max. file size 4 MB)</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mb-3 border-top pt-3">
                  <div class="mb-3">
                    <label for="" class="font-medium font-18 mb-3"
                      >Choose business type</label
                    >
                    <div class="d-lg-flex gap-4">
                      <div class="form-check mb-lg-0 mb-3">
                        <input
                          class="form-check-input"
                          v-model="businessType"
                          type="radio"
                          value="PROPRIETORSHIP"
                          id="Proprietorship"
                          name="business-type"
                        />
                        <label class="form-check-label" for="Proprietorship"
                          >Sole Proprietorship</label
                        >
                      </div>

                      <div class="form-check mb-lg-0 mb-3">
                        <input
                          class="form-check-input"
                          v-model="businessType"
                          type="radio"
                          value="PARTNERSHIP"
                          id="Partnership"
                          name="business-type"
                        />
                        <label class="form-check-label" for="Partnership"
                          >Partnership</label
                        >
                      </div>
                      <div class="form-check mb-lg-0 mb-3">
                        <input
                          class="form-check-input"
                          v-model="businessType"
                          type="radio"
                          value="LLP"
                          id="llp"
                          name="business-type"
                        />
                        <label class="form-check-label" for="llp"
                          >Limited Liability Partnership (LLP)</label
                        >
                      </div>
                      <div class="form-check mb-lg-0 mb-3">
                        <input
                          class="form-check-input"
                          v-model="businessType"
                          type="radio"
                          value="PRIVATE_LIMITED"
                          id="PrivateLimited"
                          name="business-type"
                        />
                        <label class="form-check-label" for="PrivateLimited"
                          >Private Limited Company</label
                        >
                      </div>

                      <div class="form-check mb-lg-0 mb-3">
                        <input
                          class="form-check-input"
                          v-model="businessType"
                          type="radio"
                          value="HUF"
                          id="huf"
                          name="business-type"
                        />
                        <label class="form-check-label" for="huf"
                          >Hindu Undivided Family (HUF)</label
                        >
                      </div>

                      <div class="form-check mb-lg-0 mb-3">
                        <input
                          class="form-check-input"
                          v-model="businessType"
                          type="radio"
                          value="COOPERATIVE_SOCIETY"
                          id="CooperativeSociety"
                          name="business-type"
                        />
                        <label class="form-check-label" for="CooperativeSociety"
                          >Cooperative Society</label
                        >
                      </div>

                      <div class="form-check mb-lg-0 mb-3">
                        <input
                          class="form-check-input"
                          v-model="businessType"
                          type="radio"
                          value="PUBLIC_LIMITED"
                          id="PublicLimited"
                          name="business-type"
                        />
                        <label class="form-check-label" for="PublicLimited"
                          >Public Limited Company</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <!-- --------------------------------------------------Add Partner---------------------------------------------------- -->
                <div
                  class="col-12 mb-4 d-flex justify-content-between border-top pt-3 align-items-center"
                >
                  <h4 class="font-18 font-bold">Partner Details</h4>
                  <button
                    type="button"
                    id="add-partner-details"
                    class="btn btn-primary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#AddPartnerModal"
                  >
                    + Add Partner Details
                  </button>
                </div>
                <div class="col-12 mb-4">
                  <div v-if="!partnerList || partnerList.length == 0">
                    <span>No Partner Available</span>
                  </div>
                  <div class="row" v-else>
                    <div class="col-lg-6 mb-2" v-for="(item, i) in partnerList" :key="i">
                      <div class="card p-3 border rounded">
                        <div class="row">
                          <div class="col-6 font-bold mb-2">Partner {{ i + 1 }}</div>
                          <div class="col-6 text-end mb-2">
                            <a
                              v-on:click="deletePartner(i)"
                              class="font-16"
                              style="cursor: pointer"
                              ><i
                                class="bi bi-trash text-danger"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Remove"
                              ></i
                            ></a>
                          </div>
                          <div class="col-lg-4">
                            <label class="form-label mb-0">Partner’s name</label>
                            <div class="font-medium">{{ item.name }}</div>
                          </div>
                          <div class="col-lg-4">
                            <label class="form-label mb-0">Partner’s mobile number</label>
                            <div class="font-medium">
                              {{ item.mobile_number }}
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <label class="form-label mb-0">Partner’s PAN Number</label>
                            <div class="font-medium">{{ item.pan_number }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- -----------------------------------------------Add  secondary number-------------------------------------------------------------------- -->
                <div class="col-12 border-top pt-3">
                  <p class="font-bold font-18 mb-4">Add secondary number(optional)</p>

                  <p class="text-secondary font-14">
                    Note: these numbers will have limited access like manager
                  </p>
                </div>
                <div class="col-lg-4" v-for="(item, i) in secondaryList" :key="i">
                  <div class="mb-3">
                    <label for="" class="form-label">Secondary number (optional)</label>
                    <div class="input-group col-lg-4">
                      <Field
                        v-slot="{ field }"
                        :name="'secondary-number' + i"
                        rules="mobileNumber"
                        v-model="item.mobile_number"
                      >
                        <input
                          type="text"
                          v-bind="field"
                          class="form-control"
                          v-model="item.mobile_number"
                          placeholder="Enter Mobile Number"
                        />
                      </Field>
                      <button
                        type="button"
                        class="btn btn-outline-danger"
                        style="height: 44px"
                        @click="removeSecondaryNumber(i)"
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                    </div>
                  </div>
                  <div>
                    <ErrorMessage
                      :name="'secondary-number' + i"
                      class="validation-msg mb-3"
                    />
                  </div>
                </div>
                <div class="col-lg-12 mb-3">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    v-on:click="addSecondaryNumber"
                  >
                    + Add More Mobile number
                  </button>
                </div>

                <!-- -------------------------------------------------Add address--------------------------------------------------------------------------- -->

                <div
                  class="col-12 mb-4 d-flex justify-content-between border-top pt-3 align-items-center"
                >
                  <h4 class="font-18 font-bold">Workshop Address</h4>
                  <button
                    v-if="!this.workshopAddress"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#AddAddressModal"
                    class="btn btn-primary px-5"
                    v-on:click="showAddressModel('Add Address')"
                  >
                    + Add Address
                  </button>
                </div>

                <div class="col-lg-4 mb-4">
                  <div v-if="!this.workshopAddress">
                    <span>No Address Available</span>
                  </div>
                  <div class="card p-3 rounded-3" v-if="this.workshopAddress">
                    <div class="d-flex gap-3">
                      <div>
                        <div class="border px-2 py-1 rounded">
                          <i class="bi bi-house-door text-brand font-18"></i>
                        </div>
                      </div>
                      <div>
                        <!-- <p class="font-medium mb-1">
                          {{ this.details.address }}
                        </p> -->
                        <p class="font-14 text-secondary mb-0">
                          {{ this.workshopAddress }}
                        </p>
                        <div class="font-18 mt-3">
                          <a
                            data-bs-toggle="offcanvas"
                            data-bs-target="#AddAddressModal"
                            v-on:click="showAddressModel('Edit Address')"
                            ><i
                              class="bi bi-pencil-square text-brand me-2"
                              style="cursor: pointer"
                            ></i
                          ></a>
                          <!-- <a
                            ><i class="bi bi-trash text-danger" v-on:click="removeAddress"></i
                          ></a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 text-center border-top pt-3 mb-5">
                  <button
                    id="save-data"
                    class="btn btn-primary px-5"
                    native-type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          </div>

          <!-- Add Address  -->
          <div
            class="offcanvas offcanvas-end"
            tabindex="-1"
            id="AddAddressModal"
            aria-labelledby="AddAddressModalLabel"
            style="width: 700px"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="AddAddressModalLabel">
                {{ showValue }}
              </h5>
              <button
                id="close-address-popup"
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                @click="getWorkshopDetails"
              ></button>
              <button
                id="close-address-popup1"
                hidden
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <div class="position-relative">
                <input
                  type="text"
                  class="form-control mb-2"
                  placeholder="Search Address"
                  id="autocompleteInput"
                  v-model="searchAddress"
                />
              </div>
              <div class="mb-3">
                <div ref="map" class="google-map card p-4 mt-3"></div>
              </div>
              <Form @submit="saveAddress" class="form-style">
                <div class="mb-3">
                  <label for="" class="font-14 mb-2"
                    >Shop No / Building Name <span class="text-danger">*</span>
                  </label>
                  <Field
                    v-slot="{ field }"
                    name="address-line-1"
                    rules="required:Shop No / Building Name"
                    v-model="details.address_line_1"
                  >
                    <input
                      type="text"
                      v-bind="field"
                      class="form-control"
                      v-model="details.address_line_1"
                      placeholder="Enter Shop No / Building Name"
                    />
                  </Field>
                </div>
                <div>
                  <ErrorMessage name="address-line-1" class="validation-msg" />
                </div>
                <div class="mb-3">
                  <label for="" class="font-14 mb-2"
                    >Area / Sector / Locality <span class="text-danger">*</span>
                  </label>
                  <Field
                    v-slot="{ field }"
                    name="address-line-2"
                    rules="required:Area / Sector / Locality"
                    v-model="details.address_line_2"
                  >
                    <input
                      type="text"
                      v-bind="field"
                      class="form-control"
                      v-model="details.address_line_2"
                      placeholder="Area / Sector / Locality"
                    />
                  </Field>
                </div>
                <div>
                  <ErrorMessage name="address-line-2" class="validation-msg" />
                </div>
                <div class="row mb-4">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="" class="font-14 mb-2"
                        >Pin code <span class="text-danger">*</span></label
                      >
                      <Field
                        v-slot="{ field }"
                        name="pincode"
                        rules="required:pincode"
                        v-model="details.pincode"
                      >
                        <input
                          v-bind="field"
                          type="text"
                          v-model="details.pincode"
                          class="form-control"
                          placeholder="Enter pincode"
                      /></Field>
                    </div>
                    <ErrorMessage name="pincode" class="validation-msg" />
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="" class="font-14 mb-2"
                        >Nearby Landmarks <span class="text-danger">*</span></label
                      >
                      <Field
                        v-slot="{ field }"
                        name="landmark"
                        rules="required:Nearby Landmarks"
                        v-model="details.landmark"
                      >
                        <input
                          v-bind="field"
                          type="text"
                          v-model="details.landmark"
                          class="form-control"
                          placeholder="Enter Nearby Landmarks"
                      /></Field>
                    </div>
                    <ErrorMessage name="landmark" class="validation-msg" />
                  </div>
                </div>

                <div class="text-center">
                  <button id="save-address" native-type="submit" class="btn btn-primary">
                    Save Details
                  </button>
                </div>
              </Form>
            </div>
          </div>

          <!-- -------------------------------------------------Add partner form------------------------------------------------------------------ -->
          <Form @submit="savePartner" class="form-group">
            <div
              class="offcanvas offcanvas-end"
              tabindex="-1"
              id="AddPartnerModal"
              aria-labelledby="AddPartnerModalLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="AddPartnerModalLabel">Partner Details</h5>

                <button
                  type="button"
                  id="close-popup-button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body form-style overflow-y-auto">
                <p class="font-12 text-secondary">
                  Note: all partners will have same authority to application
                </p>

                <div
                  class="border p-2 mb-2"
                  v-for="(item, i) in tempPartnerList"
                  :key="i"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <h4 class="font-18">Partner details</h4>
                    </div>
                    <div class="col-md-6 text-end">
                      <a
                        v-on:click="removePartner(i)"
                        class="font-16"
                        style="cursor: pointer"
                        ><i
                          class="bi bi-trash text-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Remove"
                        ></i
                      ></a>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="mb-3">
                      <label for="" class="form-label">Partner’s name</label>
                      <Field
                        v-slot="{ field }"
                        :name="'partner-name' + i"
                        rules="required:partner’s name"
                        v-model="item.name"
                      >
                        <input
                          type="text"
                          v-bind="field"
                          class="form-control"
                          v-model="item.name"
                          placeholder="Enter Partner’s name"
                        />
                      </Field>
                    </div>
                    <ErrorMessage :name="'partner-name' + i" class="validation-msg" />
                  </div>
                  <div class="mb-3">
                    <div class="mb-3">
                      <label for="" class="form-label">Partner’s mobile number</label>
                      <Field
                        v-slot="{ field }"
                        :name="'partner-mobile-number' + i"
                        rules="required:mobile number|mobileNumber"
                        v-model="item.mobile_number"
                      >
                        <input
                          type="text"
                          v-bind="field"
                          class="form-control"
                          v-model="item.mobile_number"
                          placeholder="Enter mobile number"
                        />
                      </Field>
                    </div>
                    <ErrorMessage
                      :name="'partner-mobile-number' + i"
                      class="validation-msg"
                    />
                  </div>
                  <div class=" ">
                    <div class="mb-3">
                      <label for="" class="form-label">Partner’s PAN Number </label>
                      <Field
                        v-slot="{ field }"
                        :name="'partner-pan-number' + i"
                        rules="panNumber"
                        v-model="item.pan_number"
                      >
                        <input
                          type="text"
                          v-bind="field"
                          class="form-control"
                          v-model="item.pan_number"
                          placeholder="Enter PAN Number"
                        />
                      </Field>
                    </div>
                    <ErrorMessage
                      :name="'partner-pan-number' + i"
                      class="validation-msg"
                    />
                  </div>
                </div>
                <div class="text-end">
                  <button
                    type="button"
                    id="add-partner"
                    class="btn btn-secondary btn-sm"
                    v-on:click="addNewPartner"
                  >
                    + Add New Partner
                  </button>
                </div>
              </div>
              <div class="offcanvace-footer p-3">
                <button
                  native-type="submit"
                  id="save-partner"
                  class="btn btn-primary w-100"
                >
                  Save
                </button>
              </div>
            </div>
          </Form>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>
    </section>
  </div>
</template>
<script>
import "./onboarding.scss";
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent.vue";
import { Loader } from "@googlemaps/js-api-loader";
export default {
  name: "PersonalDetail",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },

  data() {
    return {
      id: "",
      workshopDetails: {
        mobile_number: "",
      },
      workshopName: "",
      email: "",
      primaryNumber: "",
      businessType: "LLP",
      partnerList: [],
      tempPartnerList: [
        {
          name: "",
          pan_number: "",
          mobile_number: "",
          dial_code: "+91",
          country_code: "IN",
        },
      ],
      secondaryList: [
        {
          id: 0,
          mobile_number: "",
          dial_code: "+91",
          country_code: "IN",
        },
      ],
      primaryId: null,
      workshopAddress: "",
      workshopPincode: "",
      showModal: false,
      googleMap: null,
      mapOptions: {
        center: { lat: 18.5204, lng: 73.8567 },
        zoom: 12,
      },
      marker: null,
      searchAddress: "",
      autocomplete: null,
      google: null,
      details: {},
      showValue: "",
      pageType: "",
      outlet_workshop_id: "",
      workshop_count: null,
      panCardImage: null,
      authorizationImage: null,
      authorization_delete: "N"
    };
  },

  mounted() {
    this.pageType = localStorage.getItem("page_type");
    if (this.pageType == "ADD_OUTLET") {
      this.id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.id = localStorage.getItem("workshop_id");
    }
    this.getCurrentLocation();
    this.getMeCall();
    if (this.id) {
      this.getWorkshopDetails();
    }
  },
  methods: {
    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            console.log("Current Latitude:", latitude);
            console.log("Current Longitude:", longitude);

            this.mapOptions.center = { lat: latitude, lng: longitude };
          },
          (error) => {
            console.error("Error getting current location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    },
    handleBack() {
      if (this.workshop_count > 0) {
        this.$router.go(-1);
      }
    },
    getMeCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          if (res && res.result) {
            this.workshopDetails.mobile_number = res.result.mobile_number;
            this.workshop_count = res.result.workshop_count;
          }
        })
        .catch(() => {});
    },
    getWorkshopDetails() {
      if (this.id) {
        if (this.$refs.errorComponent) {
          this.$refs.errorComponent.showLoader(true);
        }
        this.$api
          .getAPI({
            _action: "/get-workshop/" + this.id,
            _body: {},
            _hide_loader: true,
          })
          .then((res) => {
            if (res && res.info) {
              this.workshopDetails = res.info;
              if (res.info.workshop) {
                this.details = res.info.workshop;
                // this.panCardImage = res.info.pan_image_front;
                // this.authorizationImage = res.info.authorization_letter;
                this.workshopName = res.info.workshop.workshop_name;
                localStorage.setItem("workshop_name", this.workshopName);
                this.email = res.info.workshop.email;
                this.businessType = res.info.workshop.business_type;
                this.workshopPincode = res.info.workshop.pincode;
                this.workshopAddress = `${res.info.workshop.address_line_1 || ""} ${
                  res.info.workshop.address_line_2 || ""
                } ${res.info.workshop.pincode || ""} ${
                  res.info.workshop.landmark || ""
                }`.trim();
              }
              if (res.info.primary_list && res.info.primary_list.length > 0) {
                this.primaryNumber = res.info.primary_list[0].mobile_number;
                this.primaryId = res.info.primary_list[0].id;
              }
              if (res.info.partner_list && res.info.partner_list.length > 0) {
                this.partnerList = res.info.partner_list;
                this.tempPartnerList = [...res.info.partner_list];
              }
              if (this.partnerList.length === this.tempPartnerList.length) {
                this.tempPartnerList.push({
                  id: 0,
                  name: "",
                  pan_number: "",
                  mobile_number: "",
                  dial_code: "+91",
                  country_code: "IN",
                });
              }
              if (res.info.secondary_list && res.info.secondary_list.length > 0) {
                this.secondaryList = res.info.secondary_list;
              }
            }
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
            }
          })
          .catch(() => {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
              this.$refs.errorComponent.setTitle("");
            }
          });
      }
    },

    saveDeatils() {
      if (!this.workshopAddress) {
        this.$swal({
          title: "Address is compulsory",
          text: "Please provide an address before proceeding.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }
      if ((!this.id && !this.panCardImage) || (this.id && !this.workshopDetails.pan_image_front)) {
        this.$swal({
          title: "PAN Image is compulsory",
          text: "Please provide an PAN image before proceeding.",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }
      var primaryList = [];

      var obj = {
        id: this.primaryId ? this.primaryId : 0,
        dial_code: "+91",
        country_code: "IN",
        mobile_number: this.primaryNumber,
      };
      if (this.primaryNumber) {
        primaryList.push(obj);
      }

      var filteredSecondaryList = [];

      if (this.secondaryList.length > 0) {
        for (let i in this.secondaryList) {
          if (this.secondaryList[i].mobile_number) {
            filteredSecondaryList.push(this.secondaryList[i]);
          }
        }
      }

      var requestBody = {
        name: this.workshopDetails.name,
        pan_number: this.workshopDetails.pan_number,
        workshop_name: this.workshopName,
        email: this.email,
        business_type: this.businessType,
        primary_list: primaryList,
        secondary_list: filteredSecondaryList,
        partner_list: this.partnerList,
        address_line_1: this.details.address_line_1,
        address_line_2: this.details.address_line_2,
        landmark: this.details.landmark,
        longitude: this.details.longitude,
        latitude: this.details.latitude,
        pincode: this.details.pincode,
        authorization_delete: this.authorization_delete
      };
      
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/set-workshop-info/" + this.id,
            _buttonId: "save-data",
            _body: requestBody,
          })
          .then((res) => {
            this.id = res.workshop_id;
            if (this.panCardImage) {
              this.uploadPan(this.panCardImage);
            } else if (this.authorizationImage) {
              this.uploadAuthorization(this.authorizationImage);
            } else {
              this.$router.push("/onboarding-steps");
            }
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/set-workshop-info",
            _buttonId: "save-data",
            _body: requestBody,
          })
          .then((res) => {
            if (this.panCardImage) {
              this.uploadPan();
            } else if (this.authorizationImage) {
              this.uploadAuthorization();
            } else {
              if (this.pageType == "ADD_OUTLET") {
                localStorage.setItem("outlet_workshop_id", res.workshop_id);
              } else {
                this.id = res.workshop_id;
                localStorage.setItem("workshop_id", res.workshop_id);
                this.$store.commit("setWorkshopId", res.workshop_id);
                this.setWorkshop(res.workshop_id);
              }
              this.workshopAddress = "";
              this.$router.push("/onboarding-steps");
            }
          })
          .catch(() => {});
      }
    },
    setWorkshop(id) {
      if (id) {
        this.$api
          .postAPI({
            _action: "/set-workshop",
            _body: { id: id },
            _hide_loader: true,
          })
          .then((res) => {
            localStorage.setItem("access_token", res.access_token);
            this.$store.commit("setAccessToken", res.access_token);
          })
          .catch(() => {});
      }
    },
    uploadPan() {
      this.$api
        .uploadImageAPI({
          _action: "/upload-document",
          _file: this.panCardImage,
          _key: "image",
          _body: {
            type: "PAN_FRONT",
          },
        })
        .then((res) => {
          if (res) {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            if (this.authorizationImage) {
              this.uploadAuthorization();
            } else {
              if (!this.id) {
                if (this.pageType == "ADD_OUTLET") {
                  localStorage.setItem("outlet_workshop_id", res.workshop_id);
                } else {
                  this.id = res.workshop_id;
                  localStorage.setItem("workshop_id", res.workshop_id);
                  this.$store.commit("setWorkshopId", res.workshop_id);
                  this.setWorkshop(res.workshop_id);
                }
                this.workshopAddress = "";
                this.$router.push("/onboarding-steps");
              } else {
                this.$router.push("/onboarding-steps");
              }
            }
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    uploadAuthorization() {
      this.$api
        .uploadImageAPI({
          _action: "/upload-document",
          _file: this.authorizationImage,
          _key: "image",
          _body: {
            type: "AUTHORIZED",
          },
        })
        .then((res) => {
          if (res) {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            if (!this.id) {
              if (this.pageType == "ADD_OUTLET") {
                localStorage.setItem("outlet_workshop_id", res.workshop_id);
              } else {
                this.id = res.workshop_id;
                localStorage.setItem("workshop_id", res.workshop_id);
                this.$store.commit("setWorkshopId", res.workshop_id);
                this.setWorkshop(res.workshop_id);
              }
              this.workshopAddress = "";
              this.$router.push("/onboarding-steps");
            } else {
              this.$router.push("/onboarding-steps");
            }
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    savePartner() {
      this.partnerList = [...this.tempPartnerList];
      const closebutton = document.getElementById("close-popup-button");
      closebutton.click();
      this.addNewPartner();
    },
    deletePartner(index) {
      this.partnerList.splice(index, 1);
      this.tempPartnerList.splice(index, 1);
      console.log("this.tempPartnerList--", this.tempPartnerList);
      console.log("this.partnerList--", this.partnerList);
    },
    addNewPartner() {
      this.tempPartnerList.push({
        id: 0,
        name: "",
        pan_number: "",
        mobile_number: "",
        dial_code: "+91",
        country_code: "IN",
      });
    },
    removePartner(index) {
      this.tempPartnerList.splice(index, 1);
    },
    addSecondaryNumber() {
      this.secondaryList.push({
        id: 0,
        mobile_number: "",
        dial_code: "+91",
        country_code: "IN",
      });
    },
    removeSecondaryNumber(index) {
      this.secondaryList.splice(index, 1);
    },
    moveToAddress() {
      this.$store.commit("setWorkshopDetails", this.workshopDetails);
      this.$router.push("/address");
    },
    loadGoogleMaps() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      const loader = new Loader({
        apiKey: "AIzaSyDF5xEC_aqtTstR0ItP-9-o4rg9d5Ccomk",
        version: "weekly",
        libraries: ["places"],
      });

      if (
        this.details &&
        this.details.latitude !== undefined &&
        this.details.longitude !== undefined
      ) {
        if (
          this.details &&
          !isNaN(this.details.latitude) &&
          !isNaN(this.details.longitude)
        ) {
          this.mapOptions.center = {
            lat: parseFloat(this.details.latitude),
            lng: parseFloat(this.details.longitude),
          };
        }
      }

      loader
        .load()
        .then((google) => {
          this.google = google;
          this.initMap();
          this.initAutocomplete();
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error("Error loading Google Maps API:", error);
        });
    },

    initMap() {
      this.googleMap = new this.google.maps.Map(this.$refs.map, this.mapOptions);

      const markerPosition = this.mapOptions.center;

      // if (this.marker) {
      //   this.marker.setMap(null); // Removes the existing marker from the map
      // }

      if (this.marker) {
        this.marker.setPosition(markerPosition); // Updates the marker's position
      } else {
        this.marker = new this.google.maps.Marker({
          position: markerPosition,
          map: this.googleMap,
          title: this.details.address_line_1 || "Pune, India",
          draggable: true, // Make the marker draggable
        });
      }
      // Add event listener to update coordinates and reverse geocode on drag end
      this.marker.addListener("dragend", () => {
        const position = this.marker.getPosition();
        this.reverseGeocode(position);
      });
    },

    initAutocomplete() {
      this.autocomplete = new this.google.maps.places.Autocomplete(
        document.getElementById("autocompleteInput"),
        {
          componentRestrictions: { country: "IN" },
          fields: ["address_components", "formatted_address", "geometry", "name"],
          types: ["establishment"],
        }
      );
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        if (!place.geometry) {
          console.error("No location found for input:", place);
          return;
        }

        this.addMarker(place);

        this.googleMap.setCenter(place.geometry.location);
        this.googleMap.setZoom(15);
      });
    },

    addMarker(place) {
      if (!this.googleMap) {
        console.error("Google Map not initialized yet.");
        return;
      }

      // if (this.marker) {
      //   this.marker.setMap(null); // Removes the existing marker from the map
      // }

      if (this.marker) {
        this.marker.setPosition(place.geometry.location); // Updates the marker's position
      } else {
        this.marker = new this.google.maps.Marker({
          position: place.geometry.location,
          map: this.googleMap,
          title: place.formatted_address,
          draggable: true, // Make the marker draggable
        });
      }

      // Update the workshop object with initial place details
      this.updateWorkshopCoordinates(this.marker.getPosition(), place);

      // Add event listener to update coordinates and reverse geocode on drag end
      this.marker.addListener("dragend", () => {
        const position = this.marker.getPosition();
        this.reverseGeocode(position);
      });
    },

    reverseGeocode(position) {
      const geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === "OK" && results[0]) {
          const place = results[0];
          this.updateWorkshopCoordinates(position, place);
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    },
    updateWorkshopCoordinates(position, place) {
      this.details.latitude = position.lat().toString();
      this.details.longitude = position.lng().toString();

      if (place) {
        this.details.address_line_2 = place.formatted_address || "";
        this.details.pincode = this.getPincodeFromPlace(place) || "";
        this.details.landmark = place.name || "";
      }

      console.log("Updated coordinates:", {
        latitude: this.details.latitude,
        longitude: this.details.longitude,
      });

      if (place) {
        console.log("Updated place details:", place);
      }
    },

    getPincodeFromPlace(place) {
      const addressComponents = place.address_components || [];
      for (let component of addressComponents) {
        for (let type of component.types) {
          if (type === "postal_code") {
            return component.long_name;
          }
        }
      }
      return "";
    },
    saveAddress() {
      this.workshopAddress =
        this.details.address_line_1 +
        " " +
        this.details.address_line_2 +
        " " +
        this.details.pincode +
        " " +
        this.details.landmark;
      const closebutton = document.getElementById("close-address-popup1");
      closebutton.click();
    },
    showAddressModel(value) {
      this.showValue = value;
      this.loadGoogleMaps();
    },

    panHandleImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.match(/image\/(jpeg|png|jpg)/)) {
        alert("Invalid file format. Please use JPEG or PNG.");
        return;
      }

      const maxSizeInBytes = 4 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        alert("Maximum file size allowed is 4 MB.");
        return;
      }
      this.panCardImage = file;
      this.workshopDetails.pan_image_front = file;
      const reader = new FileReader();
      reader.onload = () => {
        const inputElement = document.getElementById(`preview-pan-0`);
        if (inputElement) {
          inputElement.src = reader.result;
        }
      };
      reader.readAsDataURL(file);
    },

    deletePanCard() {
      this.panCardImage = null;
      this.workshopDetails.pan_image_front = null;
    },

    authorizationHandleImageUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.match(/image\/(jpeg|png|jpg)/)) {
        alert("Invalid file format. Please use JPEG or PNG.");
        return;
      }

      const maxSizeInBytes = 4 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        alert("Maximum file size allowed is 4 MB.");
        return;
      }
      this.authorizationImage = file;
      this.workshopDetails.authorization_letter = file;
      this.authorization_delete = 'N';
      const reader = new FileReader();
      reader.onload = () => {
        const inputElement = document.getElementById(`preview-authorization-0`);
        if (inputElement) {
          inputElement.src = reader.result;
        }
      };
      reader.readAsDataURL(file);
    },

    deleteAuthorizationImage() {
      this.authorizationImage = null;
      this.workshopDetails.authorization_letter = null;
      this.authorization_delete = 'Y';
    },
  },
};
</script>

<style scoped>
.google-map {
  display: flex;
  width: 100%;
  height: 490px;
}
</style>